<template>
  <div class="page" id="default-landing-page">
    <div class="page-header">
      <div class="logo">
        <img src="/img/icon/lugstay.svg" alt="lugstay" />
      </div>
    </div>

    <div class="page-body" v-if="isNative">
      <div class="message">
        <p>앱 업데이트가 필요해요.</p>
      </div>

      <div class="actions">
        <button type="button" class="button-retry" @click="onClickUpdate">
          <span>앱 업데이트</span>
        </button>
      </div>
    </div>

    <div class="page-body" v-else>
      <!-- <div class="emotion">
        <img :src="selectedCase.icon" alt="Wow!!" />
      </div> -->

      <div class="message">
        <p>페이지 이동중입니다.</p>
      </div>

      <div class="actions">
        <!-- <button type="button" class="button-retry" @click="onClickRetry">
          <span>다시 시도하기</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { WNInterface } from "vue-wni";

export default {
  components: {},
  computed: {
    isNative() {
      return WNInterface.isNative;
    },
  },
  mounted() {
    if (!WNInterface.isNative) {
      location.href = "https://bwc.lugstay.com/kntojg";
    }
  },
  methods: {
    onClickUpdate() {
      if (WNInterface.isAndroid) {
        const update_link =
          "https://play.google.com/store/apps/details?id=com.lugstay.app";

        this.wni.execute("wnOpenURL", { url: update_link, exit: true });
      } else if (WNInterface.isIOS) {
        const update_link = "https://apps.apple.com/kr/app/lugstay/id1472429427";

        this.wni.execute("wnOpenURL", { url: update_link, exit: true });
      }
    },
    onClickRetry() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .page-header {
    padding: 0;
    width: 100%;
    height: unit(60);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .logo {
      margin-left: unit(20);
      cursor: pointer;

      img {
        width: unit(120);
      }
    }
  }

  .page-body {
    flex: 1;
    padding: unit(32) 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .emotion {
      margin-top: auto;

      img {
        width: unit(120);
        height: unit(120);
      }
    }

    .message {
      margin-top: unit(32);
      margin-bottom: unit(32);

      p {
        text-align: center;
        line-height: 2;

        font-weight: 400;
        font-size: unit(16);
        color: #787878;

        &.primary {
          font-size: unit(20);
          font-weight: 600;
          color: #101010;
        }
      }
    }

    .actions {
      margin-bottom: auto;
      width: 100%;
      button {
        cursor: pointer;
        width: unit(162);
        height: unit(50);
        margin: 0 auto;
        border-radius: unit(12);
        background-color: #26bbef;
        font-weight: 600;
        font-size: unit(16);
        color: #fefefe;
      }
    }
  }
}
</style>
```
